import React, { MouseEvent } from 'react';

import { blue } from '@ant-design/colors';
import { Box, BoxProps, Skeleton, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { DatesResponse } from '../../shared/api/book';
import { checkBookingDay } from './lib/date';

import moment, { Moment } from 'moment';

interface CustomCellProps {
  date: Moment;
  selectedDay: Moment;
  setDay: () => void;
  isLoading: boolean;
  bookingDays: DatesResponse["booking_days"];
}

export const CustomCell = ({
  date,
  selectedDay,
  isLoading,
  bookingDays,
  setDay,
}: CustomCellProps) => {
  const isToday = moment().isSame(date, "date");
  const isSelectedDay = date.isSame(selectedDay, "date");
  const isDisabled = !checkBookingDay(date, bookingDays);

  const makeBoxStyle = () => {
    const styles: BoxProps = {};
    if (isSelectedDay) styles.background = blue.primary;
    if (isToday) styles.border = `1px solid ${blue.primary}`;

    return styles;
  };

  if (isLoading) {
    return (
      <SkeletonContainer zIndex={10}>
        <Skeleton width="100%" height="100%" />
      </SkeletonContainer>
    );
  }

  const handleClick = (e: MouseEvent) => {
    if (isDisabled) {
      e.stopPropagation();
    } else {
      setDay();
    }
  };

  return (
    <Wrapper zIndex={3} onClick={handleClick} cursor={isDisabled ? "default" : "pointer"}>
      {isDisabled && <BlockBox onClick={(e) => e.stopPropagation()} />}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box width="fit-content" zIndex={4} position="relative">
          <BorderBox zIndex={3} borderRadius={4} position="absolute" {...makeBoxStyle()} />
          <Text
            userSelect="none"
            position="relative"
            zIndex={5}
            color={isSelectedDay ? "#fff" : isDisabled ? "rgba(0,0,0, 0.25)" : "#000"}
            fontWeight={isSelectedDay ? "600" : undefined}
          >
            {date.format("D")}
          </Text>
        </Box>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
`;

const BorderBox = styled(Box)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px;
  position: absolute;
`;

const SkeletonContainer = styled(Box)`
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 95%;
`;

const BlockBox = styled(Box)`
  height: 40px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 3;
  background-color: #f5f5f5;
`;
