import React from 'react';

import { citiesStore } from './cities.store';
import { registerStore } from './register.store';
import { smsConfirm } from './sms-store';
import { workTimes } from './work-times.store';

const appStores = {
  citiesStore,
  registerStore,
  workTimes,
  smsConfirm,
};

/* Store Helpers */

export type TStore = typeof appStores;
export type TKeysStores = keyof typeof appStores;

const StoreContext = React.createContext<TStore | null>(null);

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  return <StoreContext.Provider value={appStores}>{children}</StoreContext.Provider>;
};

export const useStore = () => {
  const store = React.useContext(StoreContext);
  if (!store) {
    throw new Error("useStore must be used within a StoreProvider.");
  }

  return store;
};
