import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Alert, AlertIcon, Box, Button, Heading, Input, InputGroup, InputLeftAddon, Stack } from "@chakra-ui/react";

import { Routes } from "../../shared/enums/routes";
import { AppTemplate } from "../../shared/ui/AppTemplate";
import { useStore } from "../../stores/index";
import { getAnalytics, logEvent } from "firebase/analytics";
import styled from "@emotion/styled";

function isNumeric(value: string) {
  if (value === "") {
    return true;
  }

  return /^-?\d+$/.test(value);
}

export const SMSConfirmPage = observer(() => {
  const [username, setUserName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [isVisited, setIsVisited] = useState<boolean | undefined>(undefined);
  // const [error, setError] = useState<boolean>();

  // const [codeError, setCodeError] = useState<boolean>();
  // const [code, setCode] = useState<string>("");
  const code = null;

  const { smsConfirm, registerStore, workTimes } = useStore();

  const history = useHistory();
  const analytics = getAnalytics();

  /*   const handleSendCode = () => {
    const phoneChanged = smsConfirm.phone !== phone;
    const usernameChanged = smsConfirm.username !== username;
    const isSomeChanged = phoneChanged || usernameChanged;

    if (username && phone && phoneChanged && isSomeChanged) {
      if (phone.length !== 10) {
        highlightError();
        return;
      }

      smsConfirm.sendCode({
        username,
        phone,
        companyId: registerStore.selectedCompany?.id || 0,
      });
      setCode("");

      logEvent(analytics, "send_sms", { phone });
      authAPI.sendPhone({ phone });
    } else {
      smsConfirm.setCodeError("Введите данные");
      highlightError();
    }
  }; */

  /* const highlightError = async () => {
    setError(true);
    await sleep(500);
    setError(false);
  }; */

  const onSubmit = async () => {
    if (!code) {
      if (!smsConfirm.phone) {
        // const user = await smsConfirm.submitCode(code);
        const user = null;
        if (!user) {
          if (workTimes.selectedTime) {
            // registerStore.setUser();
            const result = await registerStore.createAppointment({
              companyId: registerStore.selectedCompany?.id || 0,
              date: workTimes.selectedTime,
              email: "mislyf@yandex.ru",
              phone: phone,
              comment: isVisited ? "ПО АКЦИИ, НО УЖЕ БЫЛА У ВАС" : undefined,
              name: username,
            });

            if (result) {
              // logEvent(analytics, "send_code", { phone: phone });
              logEvent(analytics, "purchase", {
                coupon: "XXX",
                currency: "RUB",
                value: registerStore.selectedService?.price_min,
                transaction_id: "T_XXX",
                items: [
                  {
                    item_id: registerStore.selectedService?.id,
                    item_name: registerStore.selectedService?.title,
                    price: registerStore.selectedService?.price_min,
                  },
                ],
              });
              smsConfirm.resetStore();
              registerStore.resetStore();
              workTimes.resetStore();
              history.push(Routes.Success);
              return;
            } else {
              history.push(Routes.Error);
            }
          } else {
            console.log("Не выбрано время");
          }
        } else {
          console.log("Введенный смс код не совпадает");
        }
      } else {
        smsConfirm.setSubmitError("Код на телефон еще не был отправлен");
      }
    } else {
      /* setCodeError(true);
      await sleep(500);
      setCodeError(false); */
    }
  };

  const { /* isLoadingCode, */ isSubmiting } = smsConfirm;

  return (
    <AppTemplate headerTitle='Подтверждение записи'>
      <Box padding={5} overflowY='auto'>
        {/*  <Box style={{ margin: 0, fontSize: 20, color: "#A0AEC0" }}>Шаг 1</Box> */}
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='ФИО'
                value={username}
                onChange={e => setUserName(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Номер телефона'
                value={phone}
                onChange={event => isNumeric(event.target.value) && setPhone(event?.target.value)}
              />
            </InputGroup>
            {smsConfirm.sendCodeError !== null && (
              <Alert status='error'>
                <AlertIcon />
                {smsConfirm.sendCodeError}
              </Alert>
            )}
            {smsConfirm.isCodeSent && (
              <Alert status='success'>
                <AlertIcon />
                Код успешно отправлен, на номер +7 {smsConfirm.phone}
              </Alert>
            )}
            {/* <Button disabled={isLoadingCode} onClick={handleSendCode} mt='auto'>
              Запросить код подтверждения
            </Button> */}
          </Stack>
          {/* <Box style={{ margin: 0, fontSize: 20, color: "#A0AEC0", marginTop: "auto" }}>Шаг 2</Box> */}
          <Stack spacing='1rem' style={{ marginTop: 0 }}>
            <InputGroup>
              {/* <InputLeftElement pointerEvents='none' children={<LockIcon color='gray.300' />} />
              <Input
                maxLength={10}
                type='tel'
                isInvalid={codeError}
                placeholder='Введите код'
                value={code}
                onChange={event => isNumeric(event.target.value) && setCode(event?.target.value)}
              /> */}
            </InputGroup>
            {smsConfirm.submitError !== null && (
              <Alert status='error'>
                <AlertIcon />
                {smsConfirm.submitError}
              </Alert>
            )}
            <SubTitle style={{ textAlign: "center" }}>Вы уже были у нас?</SubTitle>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button onClick={() => setIsVisited(true)} disabled={isVisited} colorScheme={"blue"} marginRight={"8px"}>
                Был(а)
              </Button>
              <Button
                onClick={() => setIsVisited(false)}
                disabled={isVisited === false}
                colorScheme={"green"}
                marginLeft={"8px"}
              >
                Не был(а)
              </Button>
            </div>
            <Button
              disabled={isSubmiting || username.length === 0 || phone.length < 10 || isVisited === undefined}
              onClick={onSubmit}
              mt='auto'
              colorScheme='teal'
              variant='solid'
            >
              Записаться
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box style={{ marginTop: "auto" }} display='flex' justifyContent='center'>
        Есть вопросы? <br />
      </Box>
      <Box p={2} display='flex' justifyContent='center'>
        <a
          id='whatsapp_widget'
          target='_blank'
          rel='noreferrer'
          href='https://wa.me/79315878205?text=Привет, у меня вопрос по акции любая зона за 700 руб'
        >
          <span>Напишите нам в WhatsApp</span>{" "}
          <svg
            version='1.1'
            id='whatsapp'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 30.667 30.667'
          >
            <path d='M30.667,14.939c0,8.25-6.74,14.938-15.056,14.938c-2.639,0-5.118-0.675-7.276-1.857L0,30.667l2.717-8.017 c-1.37-2.25-2.159-4.892-2.159-7.712C0.559,6.688,7.297,0,15.613,0C23.928,0.002,30.667,6.689,30.667,14.939z M15.61,2.382 c-6.979,0-12.656,5.634-12.656,12.56c0,2.748,0.896,5.292,2.411,7.362l-1.58,4.663l4.862-1.545c2,1.312,4.393,2.076,6.963,2.076 c6.979,0,12.658-5.633,12.658-12.559C28.27,8.016,22.59,2.382,15.61,2.382z M23.214,18.38c-0.094-0.151-0.34-0.243-0.708-0.427 c-0.367-0.184-2.184-1.069-2.521-1.189c-0.34-0.123-0.586-0.185-0.832,0.182c-0.243,0.367-0.951,1.191-1.168,1.437 c-0.215,0.245-0.43,0.276-0.799,0.095c-0.369-0.186-1.559-0.57-2.969-1.817c-1.097-0.972-1.838-2.169-2.052-2.536 c-0.217-0.366-0.022-0.564,0.161-0.746c0.165-0.165,0.369-0.428,0.554-0.643c0.185-0.213,0.246-0.364,0.369-0.609 c0.121-0.245,0.06-0.458-0.031-0.643c-0.092-0.184-0.829-1.984-1.138-2.717c-0.307-0.732-0.614-0.611-0.83-0.611 c-0.215,0-0.461-0.03-0.707-0.03S9.897,8.215,9.56,8.582s-1.291,1.252-1.291,3.054c0,1.804,1.321,3.543,1.506,3.787 c0.186,0.243,2.554,4.062,6.305,5.528c3.753,1.465,3.753,0.976,4.429,0.914c0.678-0.062,2.184-0.885,2.49-1.739 C23.307,19.268,23.307,18.533,23.214,18.38z'></path>
          </svg>
        </a>
      </Box>
    </AppTemplate>
  );
});

const SubTitle = styled(Heading)`
  @media (min-width: 1025px) {
    font-size: var(--chakra-fontSizes-md);
  }
  @media (max-width: 1024px) {
    font-size: var(--chakra-fontSizes-xl);
  }
  @media (max-width: 425px) {
    font-size: var(--chakra-fontSizes-md);
  }
`;
