import React from "react";

import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { Col, Radio } from "antd";
import { HeaderRender } from "antd/lib/calendar/generateCalendar.d";
import moment, { Moment } from "moment";

const MAX_MONTHS_VISIBLE = 2;

export const CustomHeader: HeaderRender<Moment> = ({ value, onChange }) => {
  const start = 0;
  const end = 120;
  const months = [];

  const current = value.clone();
  const month = value.month();
  const localeData = value.localeData();

  const monthButtons = [];

  for (let i = 0; i < 120; i++) {
    current.month(i);
    months.push(localeData.months(current));
  }

  for (let index = start; index < end; index++) {
    const isPast = moment().month(index).isBefore();
    if (!isPast) {
      monthButtons.push(
        <Radio.Button className='month-button' value={index} key={`${index}`}>
          {months[index]}
        </Radio.Button>
      );
    }
  }

  return (
    <div style={{ padding: "0 8px 8px" }}>
      <Container>
        <Col>
          <Radio.Group
            value={month}
            onChange={event => {
              const date = moment();
              const newDate = date.month(parseInt(event.target.value, 10));
              onChange(newDate);
            }}
          >
            {monthButtons.slice(0, MAX_MONTHS_VISIBLE)}
          </Radio.Group>
        </Col>
        <Col></Col>
      </Container>
    </div>
  );
};

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 400px) {
    align-items: center;
    justify-content: center;
    button {
      display: none;
    }
  }
`;
