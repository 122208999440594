import { action, makeObservable, observable } from 'mobx';

import { authAPI } from '../shared/api';
import { sleep } from '../shared/lib/promises';
import { Maybe } from '../types';
import { BaseStore } from './lib/baseStore.type';

class SMSConfirm implements BaseStore {
  @observable username: Maybe<string> = null;
  @observable phone: Maybe<string> = null;
  @observable sendCodeError: Maybe<string> = null;
  @observable submitError: Maybe<string> = null;
  @observable code: Maybe<string> = null;

  @observable isLoadingCode = false;
  @observable isCodeSent = false;
  @observable isSubmiting = false;

  constructor() {
    makeObservable(this);
  }

  @action
  async sendCode({
    username,
    phone,
    companyId,
  }: {
    username: string;
    phone: string;
    companyId: number;
  }) {
    this.isLoadingCode = true;
    this.phone = phone;
    this.username = username;
    try {
      await sleep(300);
      await authAPI.verifySMSCode({ phone, fullname: username, companyId });
      this.setCodeError(null);
      this.setCodeSent(true);
    } catch (error) {
      this.setCodeSent(false);
      this.setCodeError(this.cathAxiosMessage(error));

      console.log("sendCode", error);
    } finally {
      this.setLoadingCode(false);
    }
  }

  @action
  async submitCode(code: string) {
    this.isSubmiting = true;
    this.code = code;
    try {
      if (this.phone) {
        await sleep(300);
        const { data } = await authAPI.authByCode({ phone: "7" + this.phone, code });
        this.setSubmitError(null);
        return data.data;
      }
    } catch (error) {
      this.setSubmitError(this.cathAxiosMessage(error));
    } finally {
      this.setSubmiting(false);
    }
  }

  @action cathAxiosMessage(error: any): Maybe<string> {
    const msg = error?.response?.data?.meta?.message;
    if (typeof msg === "string") {
      return msg;
    }
    return null;
  }

  @action setLoadingCode(value: boolean) {
    this.isLoadingCode = value;
  }

  @action setSubmiting(value: boolean) {
    this.isSubmiting = value;
  }

  @action setCodeSent(value: boolean) {
    this.isCodeSent = value;
  }

  @action setCodeError(value: Maybe<string>) {
    this.sendCodeError = value;
  }

  @action setSubmitError(value: Maybe<string>) {
    this.submitError = value;
  }

  @action
  resetStore() {
    this.username = null;
    this.phone = null;
    this.sendCodeError = null;
    this.code = null;
    this.isLoadingCode = false;
    this.isSubmiting = false;
  }
}

export const smsConfirm = new SMSConfirm();
