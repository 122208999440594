import { DatesResponse } from '../../../shared/api/book';
import { Maybe } from '../../../types';

import moment, { Moment } from 'moment';

export const isPastDay = (date: moment.Moment) => {
  const now = moment(Date.now());
  return date.isBefore() && !date.isSame(now, "day");
};

export const checkBookingDay = (
  date: Moment,
  bookingDate: DatesResponse["booking_days"]
): boolean => {
  const [month, day] = date.format("M:D").split(":");

  const arrayOfDays: Maybe<number[]> = bookingDate[Number(month)];
  if (!arrayOfDays) return false;

  return arrayOfDays.includes(Number(day));
};
