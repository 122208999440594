import React from "react";
import { useHistory } from "react-router-dom";

import { observer } from "mobx-react-lite";

import { Box } from "@chakra-ui/layout";
import { Divider } from "@chakra-ui/react";

import { useStore } from "../../stores/index";
import { Routes } from "../enums/routes";
import { useStep } from "../hook/useStep";
import { getStepByNumber } from "./lib/steps";

import { Button } from "antd";

export const Navigations = observer(() => {
  const appStores = useStore();
  const history = useHistory();

  const { prevStep, step, nextStep } = useStep();

  const { registerStore, workTimes } = appStores;

  if (step.index > 3 && !registerStore.selectedCompany) {
    history.push(getStepByNumber(2).route);
  }

  const onBackHandler = () => {
    if (prevStep.route === Routes.Welcome) {
      if (registerStore.selectedLaser) {
        const laser = registerStore.selectedLaser;
        history.push(prevStep.route + "/" + laser);
        return;
      }
    }
    if (prevStep.route === Routes.SelectCompany) {
      if (registerStore.selectedCompany) {
        const city = registerStore.selectedCompany.city;
        history.push(prevStep.route + "/" + city);
        return;
      }
    }

    if (step.stores) {
      step.stores.forEach(store => {
        const instance = appStores[store];
        const method = instance["resetStore"];
        if (typeof method === "function") {
          instance["resetStore"]();
        }
      });
    }

    history.push(prevStep.route);
  };

  return (
    <Box mt='auto'>
      <Divider />
      <Box p={4} display='flex' justifyContent='space-between'>
        <Button disabled={!(step.index > 1)} onClick={onBackHandler}>
          Назад
        </Button>
        {step.route === Routes.SelectDate && workTimes.selectedTime && (
          <Button type='primary' onClick={() => history.push(nextStep.route)}>
            Далее
          </Button>
        )}
      </Box>
    </Box>
  );
});
