import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/index";
import { mockService } from "../../stores/register.store";
import { Routes } from "../../shared/enums/routes";
import { useHistory } from "react-router-dom";
import { variants_alex, variants_diod } from "../welcome";

export const InitService = observer(() => {
  const { registerStore } = useStore();
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();

  const currentService = [...variants_alex, ...variants_diod].find(item => item.id === formId);

  if (formId != null) {
    registerStore.setService({
      ...mockService,
      id: formId,
      id_kto: currentService?.id_kto ?? mockService.id_kto,
      title: currentService?.title ?? mockService.title,
      price_min: currentService?.cost ?? mockService.price_min,
      price_max: currentService?.cost ?? mockService.price_max,
    });
  }
  history.push(Routes.SelectCity);
  return <Redirect to={Routes.SelectCity}></Redirect>;
});
