import React from 'react';

import { Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const CardContent = () => {
  return (
    <ResponsiveText spacing={3}>
      <ResponsiveText mt="1rem" fontSize="xl" fontWeight="600">
        Мы объявляем Welcome Days!
      </ResponsiveText>
      <ResponsiveText mt="1rem" fontSize="xl">
        Только с{" "}
        <ResponsiveText fontWeight="600" display="inline">
          19 по 23
        </ResponsiveText>{" "}
        июля для всех{" "}
        <ResponsiveText fontWeight="600" display="inline">
          НОВЫХ
        </ResponsiveText>{" "}
        клиентов во всех клиниках сети Missis Laser❗️
        {"\n"}
        <ResponsiveText>
          Лазерная эпиляция любой{" "}
          <ResponsiveText fontWeight="600" display="inline">
            ОДНОЙ
          </ResponsiveText>{" "}
          зоны всего за{" "}
          <ResponsiveText fontWeight="600" display="inline">
            300
          </ResponsiveText>{" "}
          рублей 😱
        </ResponsiveText>
      </ResponsiveText>
    </ResponsiveText>
  );
};

const ResponsiveText = styled(Text)`
  @media (max-width: 420px) {
    font-size: var(--chakra-fontSizes-md);
  }
`;
