import { mockService } from "../../stores/register.store";
import { backend_instance, instance } from "./base";
import { TResponse } from "./types";

const mockServiceId = mockService.id;
const mockWorker = 0;

export interface TimesResponse {
  datetime: string;
  seance_length: number;
  sum_length: number;
  time: string;
}

export interface DatesResponse {
  working_dates: string[];
  working_days: Record<number, number[]>;

  booking_dates: string[];
  booking_days: Record<number, number[]>;
}

interface IGetDatesByStaffId {
  companyId: number;
  workerId?: number;
  selectedServices?: string[] | null;
  excludeRecordId?: number;
}

export const getDatesByStaffId = async ({
  workerId = mockWorker,
  selectedServices = [mockServiceId],
  companyId,
  excludeRecordId,
}: IGetDatesByStaffId) => {
  const params = [];

  params.push(`service_ids%5B%5D=${selectedServices && selectedServices.join("&service_ids%5B%5D=")}`);
  params.push(`staff_id=${workerId}`);
  if (excludeRecordId !== undefined) params.push(`exclude_record_id=${excludeRecordId}`);

  return await instance.get<TResponse<DatesResponse>>(`book_dates/${companyId}?${params.join("&")}`);
};

interface IGetTimesByStaffId extends IGetDatesByStaffId {
  date: string;
}

export const getTimesByStaffId = async ({
  workerId = mockWorker,
  selectedServices = [mockServiceId],
  date,
  companyId,
  excludeRecordId,
}: IGetTimesByStaffId) => {
  const params = [];
  if (selectedServices) params.push(`service_ids%5B%5D=${selectedServices.join("&service_ids%5B%5D=")}`);
  if (excludeRecordId !== undefined) params.push(`exclude_record_id=${excludeRecordId}`);

  return await instance.get<TResponse<TimesResponse[]>>(
    `book_times/${companyId}/${workerId}/${date}?${params.join("&")}`
  );
};

export type TCreateAppointment = {
  date: string;
  name: string;
  phone: string;
  email: string | null;
  comment?: string;
  workerId?: number;
  selectedServices?: number[];
  companyId?: number;
};

export interface СreateAppointmentResponse {
  0: {
    record_id: number;
    record_hash: string;
  };
  charge?: {
    url: string;
  };
}

export const createAppointment = async (data: TCreateAppointment) => {
  return await instance.post<TResponse<СreateAppointmentResponse>>(`book_record/${data.companyId}`, {
    phone: data.phone,
    fullname: data.name,
    email: data.email ?? "",
    notify_by_sms: 24,
    comment: data.comment,
    appointments: [
      {
        id: 0,
        services: data.selectedServices,
        staff_id: 0,
        datetime: data.date,
      },
    ],
    /* appointments_charges: [
      {
        id: 0,
        services: [mockServiceId],
      },
    ],
    is_support_charge: true, */
    isMobile: true,
  });
};

export const createTag = async (data: {}) => {
  return await backend_instance.post<TResponse<СreateAppointmentResponse>>(`tags/add`, data);
};
