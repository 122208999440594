import { TCompany } from "../../types/city.type";
import { GROUP_ID, instance } from "./base";
import { TResponse } from "./types";

export const getCompanies = async () =>
  await instance.get<TResponse<TCompany[]>>(`companies/?group_id=${GROUP_ID}&count=1000&forBooking=1`);

export const getCompaniesKto = async () =>
  await instance.get<TResponse<TCompany[]>>(`companies/?group_id=125985&count=1000&forBooking=1`);

export const getCompany = async (companyId?: number) => await instance.get<TResponse<TCompany>>(`company/${companyId}`);
