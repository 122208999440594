import React from "react";

import { Box, Divider, Heading, Stat, StatLabel } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { Navigations } from "./Navigation";

import { Layout } from "antd";

const { Content } = Layout;

type AppTemplateProps = {
  headerTitle: string;
  headerBody?: string;
};

export const AppTemplate: React.FC<AppTemplateProps> = ({ headerTitle, headerBody, children }) => {
  return (
    <Layout style={{ height: "100vh", backgroundImage: `url(/bg.jpg)`, backgroundSize: "cover", width: "100vw" }}>
      <OuterContainer>
        <Paper>
          <ContentContainer>
            <Box p={5}>
              <HeadingStyled textAlign='center'>{headerTitle}</HeadingStyled>
              <StatStyled>
                <StatLabel style={{ textAlign: "center", fontSize: 24 }}>{headerBody}</StatLabel>
              </StatStyled>
            </Box>
            <Box display='flex' flexDirection='column' flexGrow={1} height='0'>
              <Divider />
              {children}
            </Box>
            <Navigations />
          </ContentContainer>
        </Paper>
      </OuterContainer>
    </Layout>
  );
};

const StatStyled = styled(Stat)`
  @media (max-width: 425px) {
    & > dl > dt {
      font-size: var(--chakra-fontSizes-xs);
    }

    & > dl > dd {
      font-size: var(--chakra-fontSizes-lg);
    }
  }

  @media (max-width: 350px) {
    & > dl > dt {
      font-size: var(--chakra-fontSizes-xs);
    }

    & > dl > dd {
      font-size: var(--chakra-fontSizes-md);
    }
  }
`;

const OuterContainer = styled(Content)`
  height: 100%;
  max-width: 960px;
  max-height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(Box)`
  height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 7px 4px 14px 2px rgba(34, 60, 80, 0.2);
  background: white;
  border-radius: 28px;
`;

const Paper = styled(Box)`
  height: 100%;
  padding: var(--chakra-space-8);

  @media (max-width: 425px) {
    padding: var(--chakra-space-5);
  }
`;

const HeadingStyled = styled(Heading)`
  @media (max-width: 425px) {
    font-size: var(--chakra-fontSizes-xl);
  }
`;
