import { Routes } from "../../enums/routes";
import { TStep } from "./types";

export const initStep: TStep = { route: Routes.Welcome, index: 1 };

export const steps: TStep[] = [
  { route: Routes.SelectCity, stores: ["registerStore"], index: 2 },
  { route: Routes.SelectCompany, stores: ["registerStore"], index: 3 },
  { route: Routes.SelectDate, stores: ["workTimes"], index: 4 },
  { route: Routes.SMSConfirm, stores: ["smsConfirm"], index: 5 },
  initStep,
];

export const getCurrentStep = (path: string): TStep => {
  return steps.find(({ route }) => path.startsWith(route)) || initStep;
};

export const getStepByNumber = (num: number): TStep => {
  return steps.find(({ index: step }) => step === num) || initStep;
};
